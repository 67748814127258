import * as React from "react"
import * as styles from "../styles/header.module.scss"

const Hero = () => (
  <div className={styles.headerTitle}>
      Odvetniška pisarna Škerjanc je specializirana za področja{" "}
    <span className={styles.textWhite}>gospodarskega, delovnega</span> in{" "}
    <span className={styles.textWhite}>pogodbenega prava.</span>
  </div>
)

export default Hero
