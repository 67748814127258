// extracted by mini-css-extract-plugin
export var avatar = "featureTile-module--avatar--H7PXr";
export var featureTile = "featureTile-module--featureTile--xUh4W";
export var featureTileArrowIcon = "featureTile-module--featureTileArrowIcon--ID80x";
export var featureTileFooter = "featureTile-module--featureTileFooter--w5wTC";
export var featureTileFooterContainer = "featureTile-module--featureTileFooterContainer--KkDG4";
export var featureTileHeader = "featureTile-module--featureTileHeader--E17BN";
export var featureTileHeaderSubtitle = "featureTile-module--featureTileHeaderSubtitle--L58Vn";
export var featureTileHeaderTitle = "featureTile-module--featureTileHeaderTitle--ze51n";
export var featureTileImage = "featureTile-module--featureTileImage--s4FXN";
export var featureTileImageBorder = "featureTile-module--featureTileImageBorder--3bMuT";
export var featureTiles = "featureTile-module--featureTiles--dKUN+";
export var inlineLink = "featureTile-module--inlineLink--pPKls";