import * as React from "react"
import * as styles from "../styles/header.module.scss"
import { Link } from "gatsby"
import SkerjancLogo from "../images/skerjanc_logo.inline.svg"

const Logo = () => (
  <Link to="/">
    <SkerjancLogo className={styles.headerSkerjancLogo}  />
  </Link>
)

export default Logo
