import * as React from "react"
import * as styles from "../styles/featureTile.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import ArrowRight from "../images/arrow_right.inline.svg"
const FeatureTiles = () => {

  const mailTo = (email) => (e) => {
    e.preventDefault()
    window.open(`mailto:${email}`, "_blank")
  }

  const fixWidth = (targets) => {
    targets.forEach(target => {
      const width = target.offsetWidth
      target.style.width = `${width + 1}px`
    })
  }

  const fixWidths = () => {
    if (window.innerWidth > 1199) {
      fixWidth(document.querySelectorAll(`.${styles.featureTileHeaderSubtitle}`))
      fixWidth(document.querySelectorAll(`.${styles.featureTileFooterContainer}`))
      fixWidth(document.querySelectorAll(`.${styles.featureTileFooter}`))
    }
  }

  React.useEffect(() => {
    setTimeout(fixWidths, 100)
    window.addEventListener("resize", fixWidths)
    return () => {
      window.removeEventListener("resize", fixWidths)
    }
  })

  return (
    <div className={`${styles.featureTiles} ${styles.featureTiles}`}>
      <div className={styles.featureTile}>
        <div className={styles.featureTileHeader}>
          <div className={styles.featureTileHeaderTitle}>Marko<br/>Škerjanc</div>
          <div className={styles.featureTileHeaderSubtitle}>odvetnik</div>
        </div>
        <div className={styles.featureTileImage}>
          <StaticImage
            className={styles.avatar}
            src="../images/marko_skerjanc.jpg"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Marko Škerjanc"
          />
          <div className={styles.featureTileImageBorder} />
        </div>
        <div className={styles.featureTileFooterContainer} onClick={mailTo("marko.skerjanc@opis.si")}>
          <div className={styles.featureTileFooter}>
            <a
              className={styles.inlineLink}
              href="mailto: marko.skerjanc@opis.si"
              target="_blank"
              rel="noreferrer noopener">
              marko.skerjanc@opis.si
            </a>
          </div>
          <div className={styles.featureTileArrowIcon}>
            <ArrowRight />
          </div>
        </div>
      </div>

      <div className={styles.featureTile}>
        <div className={styles.featureTileHeader}>
          <div className={styles.featureTileHeaderTitle}>Ana<br/>Čeh</div>
          <div className={styles.featureTileHeaderSubtitle}>odvetnica</div>
        </div>
        <div className={styles.featureTileImage}>
          <StaticImage
            className={styles.avatar}
            src="../images/ana_ceh.jpg"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Ana Čeh"
          />
          <div className={styles.featureTileImageBorder} />
        </div>
        <div className={styles.featureTileFooterContainer} onClick={mailTo("ana.ceh@opis.si")}>
          <div className={styles.featureTileFooter}>
            <a
              className={styles.inlineLink}
              href="mailto: ana.ceh@opis.si"
              target="_blank"
              rel="noreferrer noopener">
              ana.ceh@opis.si
            </a>
          </div>
          <div className={styles.featureTileArrowIcon}>
            <ArrowRight />
          </div>
        </div>
      </div>

      <div className={styles.featureTile}>
        <div className={styles.featureTileHeader}>
          <div className={styles.featureTileHeaderTitle}>Mateja<br/>Ščuka</div>
          <div className={styles.featureTileHeaderSubtitle}>odvetnica</div>
        </div>
        <div className={styles.featureTileImage}>
          <StaticImage
            className={styles.avatar}
            src="../images/mateja_scuka.jpg"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Mateja Ščuka"
          />
          <div className={styles.featureTileImageBorder} />
        </div>
        <div className={styles.featureTileFooterContainer} onClick={mailTo("mateja.scuka@opis.si")}>
          <div className={styles.featureTileFooter}>
            <a
              className={styles.inlineLink}
              href="mailto: mateja.scuka@opis.si"
              target="_blank"
              rel="noreferrer noopener">
              mateja.scuka@opis.si
            </a>
          </div>
          <div className={styles.featureTileArrowIcon}>
            <ArrowRight />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeatureTiles
