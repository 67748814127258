import * as React from "react"
import * as styles from "../styles/index.module.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FeatureTiles from "../components/featureTiles"
import Logo from "../components/logo"
import Hero from "../components/hero"
import Footer from "../components/footer"
import HeroVectorLeft from "../images/hero_vector_left.inline.svg"
import HeroVectorRight from "../images/hero_vector_right.inline.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Škerjanc" />
    <div className={styles.grid}>
      <HeroVectorLeft className={styles.heroVectorLeft} />
      <HeroVectorRight className={styles.heroVectorRight} />
      <Logo />
      <Hero />
      <Footer />
      <FeatureTiles />
    </div>
  </Layout>
)

export default IndexPage
